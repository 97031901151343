import { isValid } from "gtin";
import React, { useState } from "react";
import { scanProduct } from "../../../api/BarcodeScan";
import DashboardContainer from "../../../dashboard-components/DashboardContainer";
import { useFormFieldUtils } from "../../../utils/FormHooks";
import ButtonShowCompromisedModal from "./ButtonShowCompromisedModal";
import ButtonShowParentModal from "./ButtonShowParentModal";
import PrefixClickable from "./ButtonShowPrefixModal";
import ButtonShowReclassifyModal from "./ButtonShowReclassifyModal";

// import {
//   BarcodeInput,
//   BarcodeSearchButton,
//   BottomSection,
//   Card,
//   DetailTable,
//   ImageLogo,
//   MiddleSection,
//   ProductScanContainer,
//   UpperSection,
// } from "./ProductScanNewStyle.js";
import Loader from "../../../components/loader/Loader";

const ProductScanNew = () => {
  const isValidGTIN = (GTIN) => {
    try {
      return isValid(GTIN);
    } catch (error) {
      return false;
    }
  };

  const [apiRes, setApiRes] = useState(null);
  const [showClassifyModal, setShowClassifyModal] = useState(false);
  const [showCompromisedModal, setShowCompromisedModal] = useState(false);
  const [showPrefixModal, setPrefixModal] = useState(false);
  const GTINInput = useFormFieldUtils("");
  const [validGTIN, setValidGTIN] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const openClassifyModal = () => {
    setShowClassifyModal(true);
  };

  const closeClassifyModal = () => {
    setShowClassifyModal(false);
  };

  const openCompromisedModal = () => {
    setShowCompromisedModal(true);
  };

  const closeCompromisedModal = () => {
    setShowCompromisedModal(false);
  };

  const openPrefixModal = () => {
    setPrefixModal(true);
  };

  const closePrefixModal = () => {
    setPrefixModal(false);
  };

  const searchProduct = (event) => {
    event.preventDefault();

    if (GTINInput.value.trim().length === 0) return;

    if (!isValidGTIN(GTINInput.value.trim())) {
      setValidGTIN(false);
      return;
    }

    setValidGTIN(true);
    callEndpoint(GTINInput.value.trim());
  };

  const callEndpoint = async (gtin) => {
    if (isLoading) return;
    setApiRes(null);
    setIsLoading(true);
    const apiRes = await scanProduct(gtin);
    setIsLoading(false);
    if (apiRes === false) return;
    setApiRes(apiRes.data);
    GTINInput.setValue("");
    GTINInput.focus();
  };

  const refreshPage = () => {
    callEndpoint(apiRes?.ProductInfo?.GTIN);
  };

  // const isNewProduct = () => {
  //   if (apiRes === null) return {};
  //   if (apiRes?.ProductInfo?.isNew === true) {
  //     return { "new-record": true };
  //   }
  //   return { "existing-record": true };
  // };

  // const isNewSupplier = () => {
  //   if (apiRes === null) return {};
  //   if (apiRes?.SupplierInfo?.isNew === true) {
  //     return { "new-record": true };
  //   }
  //   return { "existing-record": true };
  // };

  // const isNewPrefix = () => {
  //   if (apiRes === null) return {};
  //   if (apiRes?.PrefixesInfo?.JustAdded.length > 0) {
  //     return { "new-record": true };
  //   }
  //   return { "existing-record": true };
  // };

  return (
    <>
      <DashboardContainer title={"Product Scan"}>
        <div className="bg-gray-100 p-3 rounded flex flex-col flex-grow">
          <div className="mb-4 w-full max-w-sm mx-auto">
            <form
              onSubmit={searchProduct}
              className="flex gap-2 justify-center items-center"
            >
              <input
                autoFocus
                className="rounded py-2 w-full border border-gray px-2 bg-white"
                ref={GTINInput.ref}
                value={GTINInput.value}
                onChange={GTINInput.onChange}
                placeholder="Enter the Barcode/GTIN"
                type="text"
              />
              <button className="rounded bg-primary py-1.5 px-3 text-white">
                {isLoading ? <Loader /> : "Search"}
              </button>
            </form>
            {!validGTIN && (
              <div className="text-center pt-3">
                <p className="text-red-500">Please, enter a valid barcode</p>
              </div>
            )}
          </div>

          <div className="flex justify-between flex-row">
            <div className="flex flex-row gap-2">
              <div
                className={`${
                  apiRes?.ProductInfo?.BrandImgUrl === null ? "" : "bg-white"
                } bg-white flex flex-col`}
              >
                <span className="p-2 font-bold bg-white">Brand</span>
                <img
                  width={150}
                  height={125}
                  src={apiRes?.ProductInfo?.BrandImgUrl}
                  className="object-contain border-0"
                />
              </div>
              <div
                className={`${
                  apiRes?.ProductInfo?.ProductImgUrl === null ? "" : "bg-white"
                } bg-white flex flex-col`}
              >
                <span className="p-2 font-bold bg-white">Product Image</span>
                <img
                  src={apiRes?.ProductInfo?.ProductImgUrl}
                  width={150}
                  height={125}
                  className="object-contain border-0"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 w-full max-w-md">
              {apiRes?.ErrorMessages.length === 0 && (
                <>
                  <div className="flex gap-2">
                    <p className="font-bold w-full max-w-[100px]">GTIN</p>
                    <p className="p-0 m-0">
                      {apiRes.ProductInfo.GTIN} (ID: {apiRes.ProductInfo.ID})
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className="font-bold w-full max-w-[100px]">Brand</p>
                    <p className="p-0 m-0">{apiRes.ProductInfo.Brand}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="font-bold w-full max-w-[100px]">
                      Description
                    </p>
                    <p className="p-0 m-0">{apiRes.ProductInfo.Name}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <ButtonShowCompromisedModal
                      refreshCallback={refreshPage}
                      GTIN={apiRes?.ProductInfo?.GTIN}
                      openCompromisedModal={openCompromisedModal}
                      closeCompromisedModal={closeCompromisedModal}
                      showCompromisedModal={showCompromisedModal}
                    />
                    <ButtonShowReclassifyModal
                      productInfo={apiRes?.ProductInfo}
                      refreshCallback={refreshPage}
                      showClassifyModal={showClassifyModal}
                      openClassifyModal={openClassifyModal}
                      closeClassifyModal={closeClassifyModal}
                    />
                  </div>
                </>
              )}
              {apiRes?.ErrorMessages.map((error, index) => (
                <div key={index} className="text-left">
                  <p className="text-red-500">ERROR: {error}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 mt-3 flex-grow">
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3">
                <span className="text-sm font-semibold">Supplier</span>
              </div>
              <div className="body">
                {apiRes !== null && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Name</p>
                      <p>{apiRes.SupplierInfo?.Name ?? "NOT DEFINED"}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Address</p>
                      <p>
                        {apiRes.SupplierInfo?.Address1 ?? "NOT DEFINED"} -{" "}
                        {apiRes.SupplierInfo?.Postcode ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px] invisible"></p>
                      <p>
                        {apiRes.SupplierInfo?.City ?? "NOT DEFINED"} -{" "}
                        {apiRes.SupplierInfo?.State ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Ownership</p>
                      <p>{apiRes.SupplierInfo?.Ownership ?? "NOT DEFINED"}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">
                        Entity GLN
                      </p>
                      <p>{apiRes.SupplierInfo?.EntityGLN ?? "NOT DEFINED"}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Tax</p>
                      <p>{apiRes.SupplierInfo?.Tax ?? "NOT DEFINED"}</p>
                    </div>
                    {!apiRes.SupplierParent && (
                      <div>
                        <p colSpan="2">
                          <ButtonShowParentModal
                            refreshCallback={refreshPage}
                            CompanyID={apiRes.SupplierInfo?.ID}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {apiRes?.SupplierParent && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <p className="font-bold col-span-12 w-full">Parent</p>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Name</p>
                      <p>{apiRes.SupplierParent?.Name}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Address</p>
                      <p>
                        {apiRes.SupplierParent?.Address1 ?? "NOT DEFINED"} -{" "}
                        {apiRes.SupplierParent?.Postcode ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Address</p>
                      <p>
                        {apiRes.SupplierParent?.Address1 ?? "NOT DEFINED"} -{" "}
                        {apiRes.SupplierParent?.Postcode ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px] invisible"></p>
                      <p>
                        {apiRes.SupplierParent?.City ?? "NOT DEFINED"} -{" "}
                        {apiRes.SupplierParent?.State ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Ownership</p>
                      <p>{apiRes.SupplierParent?.Ownership ?? "NOT DEFINED"}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">
                        Entity GLN
                      </p>
                      <p>{apiRes.SupplierParent?.EntityGLN ?? "NOT DEFINED"}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Tax</p>
                      <p>{apiRes.SupplierParent?.Tax ?? "NOT DEFINED"}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3">
                <span className="text-sm font-semibold">Prefixes</span>
              </div>
              <div className="body mt-3">
                {apiRes?.PrefixesInfo?.JustAdded.length > 0 && (
                  <div>
                    {apiRes.PrefixesInfo.JustAdded.map((prefix, key) => (
                      <div key={key} className="flex gap-2 items-center">
                        <p className="font-bold">{key === 0 ? "New" : null}</p>
                        <p>{prefix}</p>
                      </div>
                    ))}
                  </div>
                )}
                {apiRes?.PrefixesInfo?.Existing.length.length > 0 && (
                  <div>
                    {apiRes.PrefixesInfo.Existing.map((prefix, key) => (
                      <div key={key} className="flex gap-2 items-center">
                        <p className="font-bold">
                          {key === 0 ? "Existing" : null}
                        </p>
                        <PrefixClickable
                          showPrefixModal={showPrefixModal}
                          closePrefixModal={closePrefixModal}
                          openPrefixModal={openPrefixModal}
                          prefix={prefix}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3">
                <span className="text-sm font-semibold">Product</span>
              </div>
              <div className="body">
                {apiRes !== null && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Source</p>
                      <p>{apiRes.ProductInfo?.Source}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">Origin</p>
                      <p>{apiRes.ProductInfo?.Origin}</p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="font-bold w-full max-w-[70px]">
                        Percentage
                      </p>
                      <p>{apiRes.ProductInfo?.Percentage}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 flex-grow mt-5">
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <span className="title no-border">
                Segment
                {apiRes !== null && (
                  <span style={{ fontWeight: "normal", marginLeft: "10px" }}>
                    {apiRes.BrickInfo?.Segment ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.SegmentDesc ?? "NOT DEFINED"}
                  </span>
                )}
              </span>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <span className="title no-border">
                Family
                {apiRes !== null && (
                  <span style={{ fontWeight: "normal", marginLeft: "10px" }}>
                    {apiRes.BrickInfo?.Family ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.FamilyDesc ?? "NOT DEFINED"}
                  </span>
                )}
              </span>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <span className="title no-border">
                Class
                {apiRes !== null && (
                  <span style={{ fontWeight: "normal", marginLeft: "10px" }}>
                    {apiRes.BrickInfo?.Class ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.ClassDesc ?? "NOT DEFINED"}
                  </span>
                )}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 flex-grow mt-5">
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <p className="pb-2 border-b font-bold">Brick</p>
              <div className="text-center mt-3">
                {apiRes !== null && (
                  <>
                    {apiRes.BrickInfo?.Brick ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.BrickDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <p className="pb-2 border-b font-bold">Grouping</p>
              <div className="text-center mt-3">
                {apiRes !== null && (
                  <>
                    {apiRes.GroupVariantDetail?.Group ?? "NOT DEFINED"} -{" "}
                    {apiRes.GroupVariantDetail?.GroupDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <p className="pb-2 border-b font-bold">Variant</p>
              <div className="text-center mt-3">
                {apiRes !== null && (
                  <>
                    {apiRes.GroupVariantDetail?.Variant ?? "NOT DEFINED"} -{" "}
                    {apiRes.GroupVariantDetail?.VariantDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <p className="pb-2 border-b font-bold">Detail</p>
              <div className="text-center mt-3">
                {apiRes !== null && (
                  <>
                    {apiRes.GroupVariantDetail?.Detail ?? "NOT DEFINED"} -{" "}
                    {apiRes?.GroupVariantDetail?.DetailDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};

export default ProductScanNew;
